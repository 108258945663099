import axios from 'axios';

// 创建axios
const service = axios.create({
  baseURL: window.location.origin.replace(/\h5/g, "")
});


/*
* 设置超时时间和跨域是否允许携带凭证
*/
axios.defaults.timeout = 10000;
axios.defaults.withCredentials = true;


/*
* 设置请求传递数据的格式（看服务器要求什么格式）
* x-www-form-urlencoded
*/
axios.defaults.headers['Content-type'] = 'application/x-www-form-urlencoded';
axios.defaults.transformRequest = data => qs.stringify(data);


/*
* 设置请求拦截器
* 客户端发送请求 -> [请求拦截器] -> 服务器
* TOKEN校验（JWT）：接受服务器返回的token，存储到vuex/本地存储中，
* 每一次向服务器发请求，我们应该把token带上
*/
function getCookie(name)  {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return unescape(arr[2]);
  else
    return null;
}
service.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers['Authori-zation'] = token;
  };
  return config;
}, error => {
  return Promise.reject(error)
});


/*
* 响应拦截器
* 服务器返回信息 -> [拦截的统一处理] -> 客户端js获取到信息
*/
service.interceptors.response.use(response => {
  if(response.data.code==401){
    localStorage.removeItem('token')
  }
  return response.data;
}, error => {
  let { response } = error
  if (response) {
    //=>服务器最起码返回结果了
    switch (response.code) {
      case 401: 
      if(sessionStorage.getItem('isApp')==1){
        if (getCookie('mobile_mobileType') == 1) {
          Android.setMobileHandlerMessage(`shop_update_authorization`)
        } else {
          window.webkit.messageHandlers.EduMessageHandler.postMessage(`shop_update_authorization`);
        }
      }else{
        localStorage.removeItem('token')
      }
        break;
      case 403: 
        break;
      case 404:
        break;
      case 500:
        break;
      case 502:
        break;
    }
    return Promise.reject(error)
  } else {
    //=>服务器连结果都没有返回
    if (!window.navigator.onLine) {
      //断网处理：可以跳转到断网页面
      return;
    }
    return Promise.reject(error);
  }
})

export default service;
