<template>
  <div id="app">
    <router-view v-slot="{ Component }" v-if="isRouterActive">
      <keep-alive>
        <component
          :is="Component"
          :key="$route.fullPath"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <component
        :is="Component"
        :key="$route.fullPath"
        v-if="!$route.meta.keepAlive"
      />
    </router-view>
    <van-back-top right="5vw" bottom="10vh" />
  </div>
</template>

<script>
// import c_header from '@/components/header.vue';
// import c_foot from '@/components/foot.vue';
import { useStore } from "vuex";
import { ref, computed, provide, nextTick } from "vue";
import { login } from "@/api/public";
import { useRouter, useRoute } from "vue-router";
export default {
  // components: { c_header, c_foot },
  setup() {
    function getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    }
    // window.alert(getCookie('Authori-zation'))
    // vuex
    const store = useStore();
    // 路由
    const router = useRouter();
    const route = useRoute();
    // 用户信息
    const userInfo = computed(
      () =>
        store.state.userInfo || JSON.parse(sessionStorage.getItem("userInfo"))
    );
    // 是否展示登录
    const isShowLogin = computed(() => store.state.isShowLogin);
    // 关闭登录回调
    const handleClose = () => {
      store.commit("isShowLoginF", false);
    };
    // 登录状态 1:密码 2:手机 3:注册
    const loginState = ref(1);
    // 密码
    const accountNumber = ref("18292417675");
    const accountPassword = ref("crmeb@123456");
    // 手机
    const phoneNumber = ref();
    const phoneCode = ref();
    const checked = ref(false);
    // 注册
    const registerPhone = ref();
    const registerCode = ref();
    const registerPassword = ref();
    const checked1 = ref(false);
    // 登录事件
    const loginHandle = () => {
      if (loginState.value == 1) {
        login({
          account: accountNumber.value,
          password: accountPassword.value,
          spread_spid: 0,
        }).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("token", res.data.token);
            store.commit("tokenF", res.data.token);
            store.commit("isShowLoginF", false);
            router.go(0);
          }
        });
      }
    };
    const isRouterActive = ref(true);
    provide("reload", () => {
      isRouterActive.value = false;
      nextTick(() => {
        isRouterActive.value = true;
      });
    });
    const isWeiXin = () => {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf('micromessenger') > -1) {
        return 1; // 是微信端
      } else {
        return 0;
      }
    }
    const isWx = isWeiXin()
    sessionStorage.setItem('isWx',isWx)
    return {
      isShowLogin,
      handleClose,
      loginState,
      accountNumber,
      accountPassword,
      phoneNumber,
      phoneCode,
      checked,
      registerPhone,
      registerCode,
      registerPassword,
      checked1,
      loginHandle,
      userInfo,
      isRouterActive,
    };
  },
};
</script>

<style lang="scss">
* {
  // margin: 0;
  // padding: 0;
  // list-style: none;
  ol li {
    list-style-type: decimal;
    list-style-position: inside;
  }
}
html,
body,
#app {
  // width: 100%;
  height: 100%;
  // box-sizing: border-box;
  // background: rgb(246, 246, 246);
}

:root {
  --el-color-primary: #02a87b !important;
  --van-dialog-confirm-button-text-color: #02a87b !important;
  --van-back-top-background: #02a87b !important;
}

</style>
