import { createRouter, createWebHashHistory } from 'vue-router';
import { userInfo } from '@/api/public';
import store from '@/store/index';

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import('@/views/login/login.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/home',
    name: 'home',
    component: ()=>import('@/views/home/home.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: ()=>import('@/views/home/productDetail.vue'),
    meta: {
      keepAlive: false
    },
  },
  {
    path: '/order',
    name: 'order',
    component: ()=>import('@/views/home/order.vue'),
    meta: {
      keepAlive: true
    },
  },
  {
    path: '/addAddress',
    name: 'addAddress',
    component: ()=>import('@/views/home/addAddress.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/addressList',
    name: 'addressList',
    component: ()=>import('@/views/home/addressList.vue'),
    meta: {
      keepAlive: false
    },
  },
  {
    path: '/teacherOauth',
    name: 'teacherOauth',
    component: ()=>import('@/views/home/teacherOauth.vue'),
    meta: {
      keepAlive: false
    },
  },
  // 订单详情
  {
    path:'/orderDetail',
    name:'orderDetail',
    component:() => import('@/views/user/order/orderDetail.vue'),
    meta: {
      requiresAuth :false
    }
  },
  // 个人中心
  {
    path: '/user',
    name: 'user',
    component:() => import('@/views/user/user.vue'),
  },
  {
    path: '/edit',
    name: 'edit',
    component:() => import('@/views/user/edit/edit.vue'),
  },
  {
    path: '/changeBindPhone',
    name: 'changeBindPhone',
    component:() => import('@/views/user/edit/changeBindPhone.vue'),
  },
  {
    path: '/editPassword',
    name: 'editPassword',
    component:() => import('@/views/user/editPassword/index.vue'),
  },
  // 订单列表
  {
    path:'/orderList',
    name:'orderList',
    component:() => import('@/views/user/order/orderList.vue')
  },
  // 收藏列表
  {
    path:'/collect',
    name:'collect',
    component:() => import('@/views/user/collect/collect.vue')
  },
  // 搜索列表
  {
    path:'/search',
    name:'search',
    component:() => import('@/views/search/search.vue')
  },
  
  // // 个人中心
  // {
  //   path: '/user',
  //   name: 'user',
  //   component:() => import('@/views/user/index.vue'),
  //   redirect:'/user/index',
  //   children:[
  //     // 默认页
  //     {
  //       path: '/user/index',
  //       name: 'personspace',
  //       component: () => import('@/views/user/personspace/personspace.vue')
  //     },
  //     // 订单页
  //     {
  //       path: '/user/order',
  //       name: 'userorder',
  //       component: () => import('@/views/user/order/order.vue')
  //     },
  //     // 订单详情
  //     {
  //       path: '/user/orderdetail',
  //       name: 'orderdetail',
  //       component: () => import('@/views/user/order/orderDetail.vue')
  //     },
  //     // 收藏页
  //     {
  //       path: '/user/collect',
  //       name: 'collect',
  //       component: () => import('@/views/user/collect/collect.vue')
  //     },
  //   ]
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  }
});

// 路由守卫--start
router.beforeEach((to, from, next) => {
  const getCookie = (name) => {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
      return unescape(arr[2]);
    else
      return null;
  }
  const isApp = getCookie('mobile_mobileType')?1:2  //1 内嵌；2非内嵌
  sessionStorage.setItem('isApp',isApp)
  if(sessionStorage.getItem('isApp')==1){
    localStorage.setItem('token',getCookie('Authori-zation'))
  }
  userInfo().then(res => {
    if (res.code == 200) {
      store.commit('userInfoF', res.data);
      sessionStorage.setItem('userInfo', JSON.stringify(res.data));
    } else {
      store.commit('userInfoF', '');
      sessionStorage.setItem('userInfo', JSON.stringify({}));
      localStorage.removeItem('token')
    };
    if(!localStorage.getItem('token')){
      if(to.path=='/home'||to.path=='/login'||to.path=='/editPassword'){
        next()
      }else{
        next('/login')
      }
    }else{
      next();
    }
  });
});

// 路由守卫--end
export default router;
